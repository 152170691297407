import * as React from "react"

import { useRetainHtmlInputValue } from "../../utils/hooks/use-retain-html-input-value"
import * as styles from "./index.module.scss"

export type Props = React.InputHTMLAttributes<
  HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
> & {
  variant?: "large" | "xmedium" | "medium"
  invalid?: boolean
} & (
    | {
        setInputValue: (value: string) => void
        id: string
      }
    | { setInputValue?: never }
  )

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    variant = "large",
    className,
    invalid = false,
    setInputValue,
    ...otherProps
  } = props

  useRetainHtmlInputValue({ htmlId: otherProps.id, setInputValue })

  return (
    <input
      ref={ref}
      className={`${styles.input} ${styles[variant]} ${
        invalid && styles.invalid
      } ${className}`}
      {...otherProps}
    />
  )
})

Input.displayName = "Input"

export default Input
