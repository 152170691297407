import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"
import { Script, WrapPageElementBrowserArgs } from "gatsby"
import * as React from "react"

import ExperimentProvider from "./src/experimentation/contexts/experiment-provider"
import { StatsigProvider } from "./src/experimentation/contexts/statsig-provider"
import { ExperimentGatsbyPageContext } from "./src/experimentation/types"
import { Pathname } from "./src/experimentation/types/base-paths"
import {
  getCleanPathname,
  isExperimentGatsbyPageContext,
} from "./src/experimentation/utils"
import { SEGMENT_LOADED_EVENT_ID } from "./src/shared-components/seo/use-segment-is-loaded"
import { isDataDogEnvironment } from "./src/utils/browser"
import { LocationState, PageProvider } from "./src/utils/contexts/page-context"

export const wrapPageElement = ({
  element,
  props: {
    pageContext,
    location: { pathname, search, state },
  },
}: WrapPageElementBrowserArgs<
  unknown,
  ExperimentGatsbyPageContext<Pathname> | Record<string, unknown>,
  LocationState
>) => {
  const cleanPathname = getCleanPathname(pathname)
  return (
    <>
      <StatsigProvider>
        {isExperimentGatsbyPageContext(pageContext) ? (
          <ExperimentProvider
            key={pathname}
            gatsbyPageContext={pageContext}
            pathname={cleanPathname}
          >
            <PageProvider
              pathname={cleanPathname}
              search={search}
              state={state}
            >
              {element}
            </PageProvider>
          </ExperimentProvider>
        ) : (
          <PageProvider pathname={cleanPathname} search={search} state={state}>
            {element}
          </PageProvider>
        )}
      </StatsigProvider>
      <Analytics />
      <SpeedInsights />
      <Script
        id="hs-script-loader"
        type="text/javascript"
        strategy="idle"
        src={`//js.hs-scripts.com/${process.env.GATSBY_HUBSPOT_PORTAL_ID}.js`}
        defer
        async
      />
      {!isDataDogEnvironment() && (
        <Script id="segment" strategy="idle" type="text/javascript" defer async>
          {`!function(){
          var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on","addSourceMiddleware","addIntegrationMiddleware","setAnonymousId","addDestinationMiddleware"];analytics.factory=function(e){return function(){var t=Array.prototype.slice.call(arguments);t.unshift(e);analytics.push(t);return analytics}};for(var e=0;e<analytics.methods.length;e++){var key=analytics.methods[e];analytics[key]=analytics.factory(key)}analytics.load=function(key,e){var t=document.createElement("script");t.type="text/javascript";t.async=!0;t.src="https://scdn.tremendous.com/ajs.js/v1/" + key + "/ajs.min.js";var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(t,n);analytics._loadOptions=e};analytics._writeKey="${process.env.GATSBY_SEGMENT_KEY}";;analytics.SNIPPET_VERSION="4.15.3";
          analytics.load("${process.env.GATSBY_SEGMENT_KEY}");
          const event = new CustomEvent("${SEGMENT_LOADED_EVENT_ID}");
          document.dispatchEvent(event);
        }}();`}
        </Script>
      )}
      {!cleanPathname.startsWith("/blog") && (
        <Script
          id="g2-rating"
          strategy="idle"
          type="text/javascript"
          defer
          async
        >
          {/* Includes a ratings schema that is included in search engines results */}
          {`(function(a,b,c,d){window.fetch("https://www.g2.com/products/tremendous/rating_schema.json").then(e=>e.json()).then(f=>{c=a.createElement(b);c.type="application/ld+json";c.text=JSON.stringify(f);d=a.getElementsByTagName(b)[0];d.parentNode.insertBefore(c,d);});})(document,"script");`}
        </Script>
      )}
      <Script
        type="text/javascript"
        src="//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
        async
      />
    </>
  )
}
